<template>
  <div class="feedback">
    <el-card class="filter-card">
      <!--数据筛选表单-->
      <el-form ref="form"  label-width="68px">
        <el-form-item label="批次号">
          <el-input v-model="searchForm.batch_id"></el-input>
        </el-form-item>
      </el-form>

        <el-form ref="form"  label-width="68px">
        <el-form-item label="兑换码">
          <el-input v-model="searchForm.license_key"></el-input>
        </el-form-item>
        </el-form>
      
              <el-form ref="form"  label-width="90px">
        <el-form-item label="兑换码状态">
                <el-select
    v-model="searchForm.key_status"
    placeholder=""
       class="custom-icon-select"
       clearable
  >
    <el-option
      v-for="item in keyStatusOption"
      :key="item.label"
      :label="item.label"
      :value="item.value"
       
    ></el-option>

  </el-select>

        </el-form-item>
        </el-form>

          <el-form ref="form"  label-width="90px">
        <el-form-item label="过期状态">
                <el-select
    v-model="searchForm.expiration_status"
    placeholder=""
       class="custom-icon-select"
       clearable
  >
    <el-option
      v-for="item in expirtaionStatusOption"
          :key="item.label"
      :label="item.label"
      :value="item.value"
       
    ></el-option>

  </el-select>

        </el-form-item>
        </el-form>
      
                <el-button
            type="primary"
            :disabled="loading"
            @click="onSearch">查询
      </el-button>

            <el-button
            type="primary"
            :disabled="loading"
            @click="openEditModel">编辑
      </el-button>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="searchfix">
        <span>根据筛选条件共查询到 {{total}} 条结果</span>
        <!-- <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="onHandleAdd"
        >添加</el-button> -->
      </div>
      <!--数据列表-->
      <el-table
        :data="dataList"
        stripe
        border
        highlight-current-row
        style="width: 100%"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column
          prop="batch_id"
          label="批次号"
          width="100px"
          sortable
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="license_key"
          label="兑换码序列号"
  
          align="center"
        >
        </el-table-column>

        <el-table-column
          prop="is_used"
          label="兑换码状态"
          align="center"
            width="180px"
          :formatter="formatterStatus"
        >
        </el-table-column>

              <el-table-column
          prop="is_expired"
          label="过期状态"
          align="center"
          width="180px"
          :formatter="formatterExpirationStatus"
        >
        </el-table-column>

        <el-table-column
          prop="used_at"
          label="兑换时间"
          align="center"
          width="180px"
          :formatter="timestampToTime"
        >
        </el-table-column>
         <el-table-column
          prop="expiration_time"
          label="过期时间"
          align="center"
          width="180px"
             :formatter="timestampToTime"
        ></el-table-column>



<!-- 
         <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              circle
              icon="el-icon-edit"
              @click="openEditModel(scope.row)"
            ></el-button>
          </template>
        </el-table-column> -->



      </el-table>
      <!--列表分页-->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :disabled = "loading"
        :current-page.sync="page"
        @current-change="onCurrentChange" />
    </el-card>

    <!-- 添加用户对话框 -->
    <el-dialog :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible" width="400px" append-to-body>
      <el-form ref="addFormRef" :model="editForm"    :rules="editFormRules"  label-width="120px">
        <el-form-item  label="批次" prop="batch_id" >
            <!-- <el-autocomplete
    v-model="state"
    :fetch-suggestions="querySearchAsync"
    placeholder="Please input"
    @select="handleSelect"
  /> -->
                <el-select
    v-model="editForm.batch_id"
    filterable 
    placeholder="请输入批次号"
       class="custom-icon-select"
       clearable
  >
    <el-option
      v-for="(item, index) in batchList"
      :key="index"
      :label="item"
      :value="item"
       
    >
  </el-option>

  </el-select>
        </el-form-item>

          <el-form-item label="时间"  prop="new_expiry">
            <el-date-picker
              v-model="editForm.new_expiry"
              type="datetime"
              align="right"
              placeholder="选择时间"
              format="yyyy-MM-dd HH:mm:ss"
              @change="handleDateChange"
            >
            </el-date-picker>
          </el-form-item>


      </el-form>

      <!--底部区域-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" :disabled = "loading">取 消</el-button>
        <el-button type="primary" @click="onEditCDCode" :disabled = "loading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getLicenseKeyList, editLicenseKeyInfo, getBatchIdList } from "../../api/licensekey";
import { KEY_STATUS, KEY_STATUS_MAP, EXPIRATION_STATUS, EXPIRATION_STATUS_MAP ,SEARCH_MAP } from "./const";

export default {
  name: "Feedback",
  data() {
    return {
      searchForm: {
        batch_id: '',
        license_key:'',
        key_status: '',
        expiration_status:''
      },
      dynamicValue:'',
      keyStatusOption : [
        { value: KEY_STATUS.NOT_REDEEMED, label: '未兑换' },
          { value: KEY_STATUS.REDEEMED, label: '已兑换' },
      ],
      expirtaionStatusOption:[
          { value: EXPIRATION_STATUS.NOT_EXPIRED, label: '未过期' },
        { value: EXPIRATION_STATUS.EXPIRED, label: '已过期' }
      ],
      batchList:[],
      title: "",     // 弹出层标题
      pid: null,     // 用户PID
      loading: true, // 表单数据加载中
      data: [],      // 产品配置列表
      dataList:[], // 兑换码列表
      page: 1,       // 当前页码
      total:0,       // 总记录数
      pageSize: 20,  // 每页大小
      dialogFormVisible: false, // 控制对话框的显示隐藏
      operType: false, // 操作类型
      /**内容编辑修改 */
      editForm:{
        batch_id:'',
        new_expiry:''
      },
      editFormRules: {
        batch_id: [{required: true, trigger: 'blur', message: "批量号不能为空"}],
          new_expiry: [{required: true, trigger: 'change', message: "时间不能为空"}]
      }
    }
  },
  created() {
    // this.loadFeedbackInfo()
    this.loadBatchIdList()
    this.loadLicenseKeysList()
  },
  methods: {
    handleDateChange(value){

    },
    loadBatchIdList(){
      getBatchIdList().then(res=>{
        this.batchList = res.data.batch_ids
      }).catch(err=>{
        this.$message({
          message: "获取批次号失败",
          type: "warning"
        })
      })
    },
    resetSearchForm(){
      this.searchForm = {
        batch_id: '',
        license_key:'',
        key_status: '',
        expiration_status:''
      }
    },

    loadLicenseKeysList(page = 1){
          this.loading = true;
          const config = {
          page:page,
      page_size:this.pageSize,
      batch_id:this.searchForm.batch_id,
      
      license_key:this.searchForm.license_key,
      is_used:this.searchForm.key_status===''?SEARCH_MAP.EMPTY:this.searchForm.key_status,
      is_expired:this.searchForm.expiration_status===''?SEARCH_MAP.EMPTY:this.searchForm.expiration_status,
      }

      getLicenseKeyList(config).then(res => {
        this.dataList = res.data.list
        this.total = res.data.total
        this.page = page

        // this.listFilter()

        //  关闭加载中
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },


    openEditModel(){ 
      this.reset()
      this.title = "修改兑换码信息";
       this.operType = true
      this.dialogFormVisible = true;

    },
    reset(){
      this.editForm = {
        batch_id:'',
        date:''
      }
    },
    onEditCDCode(){
this.$refs["addFormRef"].validate((valid)=>{
  console.log('valid',valid)
  if(valid){
    let {batch_id, new_expiry} = this.editForm

    new_expiry =  new Date(new_expiry).getTime()
    new_expiry = Math.floor(new_expiry / 1000);
    editLicenseKeyInfo({
      batch_id, new_expiry
    }).then((res)=>{
        //  添加成功，刷新列表
        this.resetSearchForm()
      this.loadLicenseKeysList(this.page);
      this.dialogFormVisible = false;
      this.loading = false;
      this.$message({
        message:  "恭喜您，修改成功",
        type: "success"
      });
  }).catch(err=>{
    this.loading = false
    this.$message({
      message: "不好意思，修改失败了哟，请重试",
      type: "warning"})

  })
  }
})
    },
    // loadFeedbackInfo(page = 1) {
    //   this.loading = true
    //   getFeedbackInfo({
    //     page,
    //     "count": this.pageSize,
    //     "pid": this.pid!= null && this.pid != "" ? Number(this.pid) : null
    //   }).then(res => {
    //     this.data = res.data

    //     this.data
    //     //  关闭加载中
    //     this.loading = false
    //   }).catch(err => {
    //     this.loading = false
    //   })
    // },
    /**兑换码状态 */
    formatterStatus(row, column, cellValue) {
       return KEY_STATUS_MAP[Number(cellValue)]
    },
    formatterExpirationStatus(row, column, cellValue) {
      return EXPIRATION_STATUS_MAP[Number(cellValue)]
    },
    timestampToTime(row, col, cellValue){
      if(cellValue == 0){
        return '-'
      }
      return new Date(cellValue * 1000 ).toLocaleString()
    },
    /**
     *  分页按钮响应交互
     */
    onCurrentChange(page) {
      this.loadLicenseKeysList(page)
    },
    /**
     *  根据条件查询
     */
    onSearch() {
      this.loadLicenseKeysList()
    },
    /**
     *  新增用户反馈信息
     */
    onHandleAdd() {
      this.reset()
      this.operType = false
      this.dialogFormVisible = true
      this.title = "添加用户反馈信息"
    },
  }
}
</script>

<style scoped>
.list-table {
  margin-bottom: 10px;
}

.filter-card ::v-deep .el-card__body{
  display: flex;
}

.filter-card ::v-deep .el-button{
  margin-left: 20px;
}

.custom-icon-select .clear-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #f56c6c;
}

.custom-icon-select .clear-icon:hover {
  color: #ff7878;
}

.custom-icon-select .select-icon {
  position: absolute;
  right: 10px;
  color: #c0c4cc;
}
</style>
