/**
 * 兑换码配置api请求
 */
import request  from "@/utils/request";

/**
 * 
 * @returns 获取兑换码列表
 */
export const getLicenseKeyList = (data) => {
  return request({
    url:'/user/v1/license/list',
    method: 'POST',
     data

  })
}

/**
 * 
 * @param {修改兑换码列表信息} data 
 * @returns 
 */
export const editLicenseKeyInfo = (data) => {
  return request({
    url:'/user/v1/license/refresh-expiry',
    method: 'POST',
    data: data
  })
}

/**
 * 
 * @returns 获取批次号列表
 */
export const getBatchIdList = () => {
  return request({
    url:'/user/v1/license/batchids',
    method: 'GET'
  })
}