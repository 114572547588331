export const KEY_STATUS = {
   'NOT_REDEEMED':0,
    'REDEEMED':1,
}

export const EXPIRATION_STATUS = {
    'NOT_EXPIRED':0,
    'EXPIRED':1,
}

export const EXPIRATION_STATUS_MAP = {
    0:'未过期',
    1:'已过期',
}

export const KEY_STATUS_MAP = {
    0:'未兑换',
    1:'已兑换',
}


export const SEARCH_MAP = {
    'FALSE':0,
    'TRUE':1,
    'EMPTY':2
}