/**
 * 广告配置API请求
 * Body 参数使用 data 设置
 * Query 参数使用 params 设置
 * Headers 参数使用 headers 设置
 */

import request from "@/utils/request";

export const getAdsList = ({ product_type, page, count, version, pid }) => {
  return request({
    // url:'/dbas/v2/ads/list',
    url: "/dbas/v3/advertisement/list",
    method: "POST",
    data: {
      product_type,
      page,
      count,
      version,
      pid
    }
  });
};

export const getAdsListV2 = ({ product_type, page, count, version, pid }) => {
  return request({
    url:'/dbas/v2/ads/list',
    method: "POST",
    data: {
      product_type,
      page,
      count,
      version,
      pid
    }
  });
};


export const adsImg = data => {
  return request({
    method: "POST",
    url: "/dbas/v2/ads/img",
    data
  });
};

export const addAds = data => {
  return request({
    method: "POST",
    // url: '/dbas/v2/ads',
    url: "/dbas/v3/advertisement",
    data
  });
};

export const addAdsV2 = data => {
  return request({
    method: "POST",
    url: '/dbas/v2/ads',
    data
  });
};

export const delAds = ({ product_type, pid, UserType }) => {
  return request({
    method: "DELETE",
    url: "/dbas/v2/ads/" + product_type + "/" + pid + "/" + UserType
  });
};
